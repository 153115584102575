import APP_CONFIG from "@/apps/core/modules/config.js";
import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "stase";
const modelPath = `/${modelName}/`;

class StasePSPD extends ModelBase {
  constructor() {
    let model = {
      id: null,
      angkatan: {id: null, mulai: ""},
      sub_departemen: {id: null, nama: ""},
      mulai: null,
      hingga: null,
      preceptor: {id: null, nama: ""},
      kelompok_set: [],
      stasepspd_set: []
    };
    let requiredFields = ["stasepspd_set", "kelompok_set"];
    super(modelName, model, requiredFields, [], modelPath);
  }

  reset() {
    super.reset();
    let apiURL = `${APP_CONFIG.baseAPIURL}${modelPath}`;
    this.setApiURL(apiURL);
  }

  getEmptyModel() {
    this.observables.stase.stasepspd_set = [];
    this.observables.stase.kelompok_set = [];
    return this.observables.stase;
  }

  validate() {
    this.resetErrors();
    if (this.observables.stase.stasepspd_set.length == 0 && this.observables.stase.kelompok_set.length == 0) {
      this.observables.errorMap.non_field_errors = "Salah satu harus diisi.";
    }
    this.calcValidity();
  }

  initObservables() {
    super.initObservables();
    this.observables.prefix = {};
    // this.observables.kelompok_pspd_map = {};
  }

  getLoadData(data) {
    let stase = super.getLoadData(data);
    let prefix = stase.angkatan.replace("/", "").replace("/", "");
    this.observables.prefix = prefix;
    stase.stasepspd_set = [];
    stase.kelompok_set = [];
    return stase;
  }

  getPayload() {
    let data_set = [];
    if (this.observables.stase.stasepspd_set.length > 0) {
      data_set = this.observables.stase.stasepspd_set.map(obj => obj.id);
    }
    for (let kelompok of this.observables.kelompok_pspd_map) {
      let pspdIdList = kelompok.pspdList.map(obj => obj.id);
      for (let pspd_id of pspdIdList) {
        data_set.push(pspd_id);
      }
    }
    let data = {"tag_pk": this.observables.stase.kelompok_id, "data_set": data_set};
    return data;
  }
}

export default StasePSPD;